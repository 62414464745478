<!-- src/components/PasswordPrompt.vue -->
<template>
  <div v-if="!isAuthenticated">
    <div class="password-prompt">
      <input v-model="password" type="password" placeholder="Enter password" class="password-input" />
      <button @click="checkPassword">Submit</button>
    </div>
  </div>
  <div v-else>
    <slot></slot> <!-- This will render the content passed inside PasswordPrompt -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      isAuthenticated: false
    };
  },

  computed: {
    image_src(){return this.$store.state.image.url}
  },

  created() {
    this.isAuthenticated = this.isAuthenticatedToday();
  },

  methods: {
    date() {
      //get first 10 characters of the date
      var date = new Date().toISOString();
      return date.substring(0, 10);
    },

    isAuthenticatedToday() {
      //console.log(`authenticatedDate: ${localStorage.getItem('authenticatedDate')} Date today: ${this.date()}`)
      return localStorage.getItem('authenticatedDate') === this.date();
    },

    checkPassword() {
      if (this.password === process.env.VUE_APP_SIMPLE_PASSWWORD) { // Set your password here
        this.isAuthenticated = true;
        localStorage.setItem('authenticatedDate', this.date());
      } else {
        alert('Wrong password!');
      }
    }
  }
}
</script>

<style>
.password-input {
  width: 50%;
  padding: 12px 20px; /* Increases the padding inside the input box */
  margin: 8px 0; /* Adds some space around the input */
  display: inline-block;
  border: 1px solid #ccc; /* Gives a light grey border */
  border-radius: 4px; /* Rounds the corners */
  box-sizing: border-box; /* Ensures padding does not affect width */
  font-size: 16px; /* Increases the font size */
}

button {
  padding: 10px 20px;
  margin-left: 20px;
  font-size: 16px;
}

.password-prompt {
  background-image: url("../assets/passwordlogo.png");
  background-size: cover;
  background-position: center;
  margin-top: -75px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>