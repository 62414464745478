<template>
  <div class="address-input-container">
    <input type="text" id="autocomplete" placeholder="Enter your address" class="address-input"/>
    <button @click="initMap">OK</button>
    <!-- have a div with 2 columns-->
    <div class="row">
      <div class="column">
        <div id="map" style="height: 400px; width: 100%; margin-top: 10px; margin-bottom: 10px;"></div>
      </div>
      <div class="column">
        <div v-if="isMapInitialized">
          <h3>Directions</h3>
          <ol>
            <li>Move, rotate or resize the rectangle.</li>
            <li>Cover your roof with as many rectangle as needed.</li>
            <li>Click on the map to add rectangle.</li>
            <li>Don't worry about overlapping rectangle.</li>
            <li>Click "Remove Rectangle" to remove a rectangle.</li>
            <li>Click "Start Over" to start over with one rectangle.</li>
          </ol>
        </div>
      </div>
    </div>
    
    <button v-if="isMapInitialized" @click="startOver">Start Over</button>&nbsp;
    <button v-if="isMapInitialized" @click="removeRectangle" :disabled="!isMultipleRectanges">Remove Rectangle</button>
    <div v-if="calculatedAreaSquares" class="area-display">
      <h3>Your Roof Details</h3>
      <div style="text-align: left; margin-left: 50px">
        <div><b>Roof pitch: </b> 
          <select v-model="pitch" :required="true" @change="calculateArea">
            <option value="1">Flat</option>
            <option value="2" :selected="true">Medium</option>
            <option value="3">Steep</option>
          </select>
        </div>
        <div><b>No. Stories: </b> &nbsp;
          <select v-model="stories" :required="true" @change="calculateArea">
            <option value="1">1</option>
            <option value="2">2+</option>
          </select>
        </div>
        <div><b>Area: </b> {{ calculatedAreaSquares }} squares ({{ calculatedAreaM2 }} m<sup>2</sup>)</div>
        <div>
          <b>Estimated Cost: </b> ${{ estimatedCostLow }} ~ ${{ estimatedCostHigh }}
        </div>
        <div><b>Insurance estimate: </b> &nbsp;
          <!--input dollar value from insurance estiamte-->
          $<input type="text" placeholder="Insurance Estimate" v-model="insurance" @input="calculateArea" @change="calculateArea" />
        </div>     
      </div>
      <div v-if="profit">
        <h3>Estimated Profit</h3>
        <div style="text-align: left; margin-left: 50px">
          Costimator 2000 estimates <b>${{ profit }}</b> profit by using Hire A Pro!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as turf from '@turf/turf';
export default {
  name: "AddressInput",
  data() {
    return {
      isMapInitialized: false,
      isMultipleRectanges: false,
      place: null,
      rectangles: [],
      calculatedAreaM2: null,
      calculatedAreaFt2: null,
      calculatedAreaSquares: null,
      pitch: 2,
      stories: 2,
      insurance: null,
      profit: null,
      estimatedCostLow: null,
      estimatedCostHigh: null
    };
  },
  mounted() {
    this.loadGoogleMapsScript();
  },
  methods: {
    loadGoogleMapsScript() {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places&callback=initAutocomplete`;
        script.async = true;
        document.head.appendChild(script);
        window.initAutocomplete = this.initAutocomplete;
      } else {
        this.initAutocomplete();
      }
    },
    initAutocomplete() {
      const input = document.getElementById('autocomplete');
      const autocomplete = new google.maps.places.Autocomplete(input, { types: ['address'] });
      autocomplete.addListener('place_changed', () => {
        this.place = autocomplete.getPlace();
      });
    },

    addRectangle(loc) {
      let northSouthDiff = 0.0001;
      let eastWestDiff = 0.0001;
      const bounds = {
        north: loc.lat()+northSouthDiff,
        south: loc.lat()-northSouthDiff,
        east: loc.lng()+eastWestDiff,
        west: loc.lng()-eastWestDiff
      };
      // Define a rectangle and set its editable property to true.
      const rectangle = new google.maps.Rectangle({
        bounds: bounds,
        editable: true,
        draggable: true,
      });
      rectangle.setMap(this.map);
      // listen to changes
      ["bounds_changed", "dragstart", "drag", "dragend"].forEach((eventName) => {
        rectangle.addListener(eventName, () => {
          //console.log({ bounds: rectangle.getBounds()?.toJSON(), eventName });
          //console.log(eventName);
          this.calculateArea();
        });
      });

      this.rectangles.push(rectangle);
      this.calculateArea();
      this.isMultipleRectanges = this.rectangles.length > 1;
    },

    removeRectangle() {
      //TODO sexier to use right-click on rectangle
      //https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu
      if(this.rectangles.length === 0) return;
      const rectangle = this.rectangles.pop();
      rectangle.setMap(null);
      this.isMultipleRectanges = this.rectangles.length > 1;
    },

    initMap() {
      const map = new google.maps.Map(document.getElementById('map'), {
        center: this.place.geometry.location,
        zoom: 20,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        //mapTypeId: google.maps.MapTypeId.ROADMAP,
        tilt: 0,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false
      });

      // Event listener for map clicks
      google.maps.event.addListener(map, 'click', (event) => {
        this.addRectangle(event.latLng);
      });

      this.map = map;
      this.addRectangle(this.place.geometry.location);
      this.isMapInitialized = true;
    },

    startOver() {
      this.isMapInitialized = false;
      //clear this.rectangles array
      this.rectangles.forEach(rectangle => {
        rectangle.setMap(null);
      });
      this.rectangles = [];
      this.calculatedAreaSquares = null;
      this.estimatedCostLow = null;
      this.estimatedCostHigh = null;
      this.initMap();

    },

    calculateArea() {
      let areaM2 = 0;
      if(this.rectangles.length > 0)
      {
        let simpleRects = this.rectangles.map(rect => {
          let bounds = rect.getBounds();
          let sw = bounds.getSouthWest();
          let ne = bounds.getNorthEast();
          return {
              x1: sw.lng(),
              y1: sw.lat(),
              x2: ne.lng(),
              y2: ne.lat()
          };
        });
        let combined = simpleRects.map(rect => 
          turf.polygon([[
              [rect.x1, rect.y1], // bottom left
              [rect.x2, rect.y1], // bottom right
              [rect.x2, rect.y2], // top right
              [rect.x1, rect.y2], // top left
              [rect.x1, rect.y1]  // back to start
          ]])
        ).reduce((acc, polygon) => {
            return acc ? turf.union(acc, polygon) : polygon;
        }, null);

        areaM2 = turf.area(combined);
      }

      const areaFt2 = areaM2 * 10.7639;  // Convert square meters to square feet
      const roofingSquares = areaFt2 / 100;  // Convert square feet to roofing squares
      this.calculatedAreaM2 = areaM2.toFixed(2);
      this.calculatedAreaFt2 = areaFt2.toFixed(2);
      this.calculatedAreaSquares = roofingSquares.toFixed(2);

      //cost calculation
      //https://docs.google.com/spreadsheets/d/1-yT6Qt6ZbybgW6zqbxhbwAJwlEHIH4bCxlmZYe9QgK4/edit#gid=0
      //A2 Area Squares
      //C2 pitch
      //B2 stories
      //low estimate
      //=(A2 * (IF(C2=1, 1.15, IF(C2=2, 1.25, IF(C2=3, 1.4, 1)))) * 1.15 * 350) + IF(B2>=2, 500, 0)
      //high estimate
      //=(A2 * (IF(C2=1, 1.15, IF(C2=2, 1.25, IF(C2=3, 1.4, 1)))) * 1.15 * 410) + IF(B2>=2, 500, 0)
      let pitchMultiplier = this.pitch == 1 ? 1.15 : this.pitch == 2 ? 1.25 : this.pitch == 3 ? 1.4 : 1;
      let storiesExtraCost = this.stories >= 2 ? 500 : 0;
      //console.log(`pitch:${this.pitch} pitchMultiplier: ${pitchMultiplier}, stories:${this.stories} storiesExtraCost: ${storiesExtraCost}`);

      let costLow = (roofingSquares * pitchMultiplier) * 1.15 * 350 + storiesExtraCost;
      let costHigh = (roofingSquares * pitchMultiplier) * 1.15 * 410 + storiesExtraCost;

      //round cost to 2 decimal places and add commas
      this.estimatedCostLow = costLow.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      this.estimatedCostHigh = costHigh.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

      //if insurance is provided and is a number, use it to calculate estimated profit
      let insuranceFloat = parseFloat(this.insurance);
      if(isNaN(insuranceFloat) || insuranceFloat < 0 || this.estimatedCostHigh == null || this.estimatedCostLow == null) {
        this.profit = null;
      } else {
        let costAvg = (costHigh + costLow) / 2;
        let profit = insuranceFloat - costAvg;
        if(profit < 0) profit = 0;
        this.profit = profit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }

    }

  },
};

</script>

<style>
.row {
  display: flex;
  /*row should take up full width*/
  width: 100%;
  text-align: left;

}

.column {
  flex: 50%;
  padding: 10px;
}

.address-input-container {
  margin: auto;
}

.address-input {
  width: 100%;
  padding: 12px 20px; /* Increases the padding inside the input box */
  margin: 8px 0; /* Adds some space around the input */
  display: inline-block;
  border: 1px solid #ccc; /* Gives a light grey border */
  border-radius: 4px; /* Rounds the corners */
  box-sizing: border-box; /* Ensures padding does not affect width */
  font-size: 16px; /* Increases the font size */
}
</style>